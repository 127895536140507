<template>
  <v-container id="widgets" fluid>
    <v-row>
      <v-col cols="12">
        <v-snackbar v-model="error_snackbar" :timeout="timeout">
          {{ error_snackbar_text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              icon
              v-bind="attrs"
              @click="error_snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-tabs active-class="blue--text" color="blue">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            <v-icon>mdi-briefcase</v-icon>
            <span class="pl-3">User</span>
          </v-tab>
          
          <v-tab-item class="pa-md-12">
            <v-sheet color="white" class="mt-5 mb-16">
              <v-row>
                <v-col>
                  <b><h2 class="text-center text-md-left">User Details</h2></b>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">User Name</span>
                  <v-subheader
                    >User name will be displayed on the admin
                    portal</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <v-text-field
                    v-model="userName"
                    placeholder="e.g Harry Kane"
                    clearable
                    :error-messages="userNameErrors"
                    required
                    @input="$v.userName.$touch()"
                    @blur="$v.userName.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Password</span>
                  <v-subheader
                    >Password that used to login admin portal</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    :error-messages="passwordErrors"
                    required
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Confirm Password</span>
                  <v-subheader
                    >Password that must same with the previous
                    field</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <v-text-field
                    v-model="cpassword"
                    :append-icon="
                      showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="showConfirmPassword ? 'text' : 'password'"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    :error-messages="cpasswordErrors"
                    required
                    @input="$v.cpassword.$touch()"
                    @blur="$v.cpassword.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="text-center">
                <v-col>
                  <v-btn
                    color="success"
                    class="white--text"
                    @click="updateUser()"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  sameAs,
  // alphaNum,
} from "vuelidate/lib/validators";

export default {
  name: "Profile",

  mixins: [validationMixin],

  validations: {
    userName: { required },
    password: {
      required,
      minLength: minLength(8),
      // valid: function (value) {
      //   const containsUppercase = /[A-Z]/.test(value);
      //   const containsLowercase = /[a-z]/.test(value);
      //   const containsNumber = /[0-9]/.test(value);
      //   const containsSpecial = /[#?!@$%^&*-]/.test(value);
      //   return (
      //     containsUppercase &&
      //     containsLowercase &&
      //     containsNumber &&
      //     containsSpecial
      //   );
      // },
    },
    cpassword: {
      sameAsPassword: sameAs("password"),
    },
  },

  components: {},

  data: () => ({
    domain: BASEURL,
    userName: "",
    company_id: "",
    user_id: "",
    password: "",
    cpassword: "",
    showPassword: false,
    showConfirmPassword: false,
    error_snackbar: false,
    error_snackbar_text: "",
    timeout: 2000,
  }),
  created() {
    if (sessionStorage.getItem("login") == null) {
      this.$router.push("/");
    } else {
      var data = JSON.parse(sessionStorage.getItem("login"));
      this.company_id = data.company_id;
      this.getAdmin();
      // this.user_id = data.user_id;
    }
  },
  watch: {},
  computed: {
    userNameErrors() {
      const errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("user name is required");
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("passsword is required");
      !this.$v.password.minLength &&
        errors.push("passsword is minimum 8 character");
      // !this.$v.password.valid &&
      //   errors.push("passsword must consist of 1 capital letter, 1 special symbol and 1 number");

      return errors;
    },

    cpasswordErrors() {
      const errors = [];
      if (!this.$v.cpassword.$dirty) return errors;
      !this.$v.cpassword.sameAsPassword &&
        errors.push("comfirm password and password is not match");
      return errors;
    },
  },
  methods: {
    getAdmin() {
      const params = new URLSearchParams();
      params.append("getAdmin", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.userName = response.data.user[0].name;
          } else {
            console.log("no admin found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    // updateColor() {
    //   const params = new URLSearchParams();
    //   params.append("update", "done");
    //   params.append("company_id", this.company_id);
    //   params.append("color", this.companyColor);

    //   axios({
    //     method: "post",
    //     url: this.domain + "/company/index.php",
    //     data: params,
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.status == "1") {
    //         this.error_snackbar = true;
    //         this.error_snackbar_text = "Update Successfully";
    //       } else {
    //         this.error_snackbar = true;
    //         this.error_snackbar_text = "Update Failed";
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>
<style>
.v-text-field__details {
  margin-top: 2px;
}
</style>